<template>
  <v-sheet
    color="surface-bg-accent"
    class="text-center"
  >
    <v-container class="py-12 md:pt-30 md:pb-16">
      <u-text
        tag="h1"
        class="mb-8 sm:mb-16 text-36 sm:text-54 md:text-64 lg:text-76 font-galano"
      >
        <u-content :document="title" />
      </u-text>
      <u-text
        v-if="subtitle"
        class="mb-4 sm:mb-6 text-22 md:text-28"
      >
        <u-content :document="subtitle" />
      </u-text>

      <v-row justify-md="center">
        <template
          v-for="(btn, index) in btns"
          :key="index"
        >
          <v-col
            cols="24"
            md="6"
            lg="5"
          >
            <u-btn
              :stacked="mdAndUp"
              size="large"
              block
              v-on="btn.on"
            >
              <span v-html="btn.text" />
            </u-btn>
          </v-col>
        </template>
      </v-row>

      <partners-section
        class="mt-16"
        color="white"
      />
    </v-container>
  </v-sheet>
</template>

<script setup lang="ts">
import type { StoryblokRichTextDocumentNode } from '@storyblok/richtext'
import { UBtn } from '#components'

defineProps<{
  title: StoryblokRichTextDocumentNode
  subtitle: StoryblokRichTextDocumentNode
}>()

const { mdAndUp } = useDisplay()
const { trackEvent } = useGtm()
const { v2Url } = useRuntimeConfig().public
const btns = computed(() => ([
  {
    text: 'Financial <br class="hidden md:inline"> adviser',
    on: {
      click: () => {
        trackEvent({
          event: 'gaEvent',
          eventAction: 'click',
          eventCategory: 'homepage',
          eventLabel: 'homepage_fifa_cta',
        })
        goToMatchIntroPage('financial-adviser')
      },
    },
  },
  {
    text: 'Mortgage <br class="hidden md:inline"> broker',
    on: {
      click: () => {
        trackEvent({
          event: 'gaEvent',
          eventAction: 'click',
          eventCategory: 'homepage',
          eventLabel: 'homepage_fama_cta',
        })
        goToMatchIntroPage('mortgage-adviser', 'mortgages')
      },
    },
  },
  {
    text: 'Insurance <br class="hidden md:inline"> broker',
    on: {
      click: () => {
        trackEvent({
          event: 'gaEvent',
          eventAction: 'click',
          eventCategory: 'homepage',
          eventLabel: 'homepage_insurance_cta',
        })
        goToMatchIntroPage('financial-adviser', 'insurance-and-protection')
      },
    },
  },
  {
    text: 'Accountant',
    on: {
      click: () => {
        trackEvent({
          event: 'gaEvent',
          eventAction: 'click',
          eventCategory: 'homepage',
          eventLabel: 'homepage_faa_cta',
        })
        goToMatchIntroPage('accountant')
      },
    },
  },
]))

function goToMatchIntroPage(serviceType: string, primaryAdviceArea: string | null = null) {
  const url = `${v2Url}/enquiry/match-intro/${serviceType}`

  return navigateTo({
    path: url,
    query: {
      ...(primaryAdviceArea !== null && { primaryAdviceArea }),
    },
  }, {
    external: true,
  })
}
</script>
